import React from "react"
import Layout from "../../layouts/default"
import { useStaticQuery, graphql } from "gatsby"

import Breadcrumb from "../../components/breadcrumb"
import Bullet from "../../components/bullet"
import BulletList from "../../components/bullet-list"
import PageTitle from "../../components/page-title"
import Paragraph from "../../components/paragraph"
import Grid from "../../components/grid"
import Seo from "../../components/seo"
import Small from "../../components/small"
import Stack from "../../components/stack"
import VideoPlayer from "../../components/video-player"

const NeuigkeitenHoelderlinsEcho = () => {
  const data = useStaticQuery(graphql`
    query NeuigkeitenHoelderlinsEcho {
      poster: file(relativePath: { eq: "videos/poster-vr.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 640, height: 640, layout: CONSTRAINED)
        }
      }
    }
  `)
  return (
    <Layout backdrop="vr">
      <Seo
        title="Hölderlins Echo"
        description="Eine Virtual-Reality-Experience des Zentrums für Medienkompetenz der
          Universität Tübingen in Zusammenarbeit mit der Hochschule der Medien
          in Stuttgart unter der Produktionsleitung von Meier und Rall
          Animation."
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Ausstellungen",
              link: "/ausstellungen",
            },
            {
              title: "Hölderlins Echo",
              link: "/ausstellungen/hoelderlins-echo",
            },
          ]}
        />
        <PageTitle>Hölderlins Echo</PageTitle>
        <Paragraph dropcap={true}>
          Wie Hölderlins Turmzimmer wohl zu seiner Zeit ausgesehen haben mag?
          Welche Möbel standen darin? Was sah und hörte der Dichter, wenn er aus
          dem Fenster schaute? Setzt euch die Brille auf und tretet ein in
          Hölderlins einstiges Turmzimmer. In einer grafisch animierten
          3D-Rekonstruktion könnt ihr diesen Erinnerungsraum nach Belieben
          erkunden und kommt dabei auch mit Hölderlins literarischen Werken in
          Berührung.{" "}
        </Paragraph>
        <Grid columns={[1, 2]}>
          <Stack>
            <Paragraph>
              Eine Virtual-Reality-Experience des Zentrums für Medienkompetenz
              der Universität Tübingen in Zusammenarbeit mit der Hochschule der
              Medien in Stuttgart unter der Produktionsleitung von Meier und
              Rall Animation.
            </Paragraph>
            <BulletList size={[3, 3, 4, 5]}>
              <Bullet>
                Regie/Projektleitung: Hannes Rall und Susanne Marschall
              </Bullet>
              <Bullet>Sound: Michael Dmoch</Bullet>
              <Bullet>Digital Paintings: Michael Meier</Bullet>
              <Bullet>3D-Layout: Jochen Rall </Bullet>
            </BulletList>
            <Small>
              Gefördert durch die Medien- und Filmgesellschaft Baden-Württemberg
              und das Referat Denkmalschutz im Wirtschaftsministerium
              Baden-Württemberg
            </Small>
          </Stack>
          <VideoPlayer
            src="/ausstellungen/hoelderlins-echo"
            poster={data.poster}
          />
        </Grid>
      </Stack>
    </Layout>
  )
}

export default NeuigkeitenHoelderlinsEcho
