import React from "react"
import PropTypes from "prop-types"
import Stack from "./stack"

export const BulletListContext = React.createContext()

/** Unordered List with Bullets in front. */
const BulletList = ({ children, icon, color, size, space }) => {
  return (
    <BulletListContext.Provider
      value={{
        bulletColor: color,
        bulletSize: size,
        bulletIcon: icon,
      }}
    >
      <Stack as="ul" space={space}>
        {children}
      </Stack>
    </BulletListContext.Provider>
  )
}

BulletList.propTypes = {
  /** element placed in front of the text */
  icon: PropTypes.string,
  /** dot color  */
  color: PropTypes.string,
  /** font size */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  /** space between list items */
  space: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
}

BulletList.defaultProps = {
  color: "text",
  space: 1,
}

export default BulletList
